import { useMoonChain } from 'hooks/useMoonChainConfig';
import { useCallback } from 'react';
import { useRecoilState } from 'recoil';
import { favoriteTokensAtom } from './atoms';

interface UseFavoriteTokensResponse {
  addFavoriteToken: (id: string) => void;
  checkFavoriteToken: (id: string) => boolean;
  favoriteTokens: string[];
  removeFavoriteToken: (id: string) => void;
}

export function useFavoriteTokens(): UseFavoriteTokensResponse {
  const moonChain = useMoonChain();

  const [favoriteTokens, setFavoriteTokens] =
    useRecoilState(favoriteTokensAtom);

  const addFavoriteToken = useCallback(
    (id: string) => {
      setFavoriteTokens((prevFavs) => {
        if (prevFavs[moonChain.key].includes(id)) return prevFavs;

        return {
          ...prevFavs,
          [moonChain.key]: [...prevFavs[moonChain.key], id],
        };
      });
    },
    [setFavoriteTokens, moonChain.key],
  );

  const checkFavoriteToken = useCallback(
    (id: string) => favoriteTokens[moonChain.key].includes(id),
    [favoriteTokens, moonChain.key],
  );

  const removeFavoriteToken = useCallback(
    (id: string) => {
      if (id === moonChain.getErc20Id(moonChain.asset)) {
        return;
      }

      setFavoriteTokens((prevFavs) => {
        return {
          ...prevFavs,
          [moonChain.key]: prevFavs[moonChain.key].filter((x) => x !== id),
        };
      });
    },
    [setFavoriteTokens, moonChain],
  );

  return {
    addFavoriteToken,
    checkFavoriteToken,
    favoriteTokens: favoriteTokens[moonChain.key],
    removeFavoriteToken,
  };
}
