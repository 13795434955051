import { ActionIcon, Box, Card, Center, Checkbox, Group, Stack, Table, Text, TextInput, Title } from '@mantine/core';
import { ConnectEvmWallet } from 'components/ConnectWallet/ConnectEvmWallet';
import { Pagination } from 'components/Pagination';
import { useAllAssetsBalancesWithFiat } from 'hooks/queries/useAssetsBalances/useAssetsBalances';
import { useIsDesktop, useIsMobile, useIsWideScreen } from 'hooks/useMedia';
import { useTranslation } from 'next-i18next';
import { useEffect, useMemo, useState } from 'react';
import { IoSearch } from 'react-icons/io5';
import { MdOutlineClear } from 'react-icons/md';
import { useUserSettings } from 'recoil/userSettings';
import { TokenBalance } from 'types/assets';
import { useAccount } from 'wagmi';
import { AssetRow } from './AssetRow';
import classes from './Assets.module.css';
import { ImportTokens } from './ImportTokens';
export function Assets() {
  const {
    t
  } = useTranslation();
  const {
    isConnected
  } = useAccount();
  const [filter, setFilter] = useState('');
  const [filteredAssets, setFilteredAssets] = useState<TokenBalance[] | undefined>();
  const [activePage, setActivePage] = useState(1);
  const [isFilteredByBalance, setIsFilteredByBalance] = useState(false);
  const allAssetsBalancesWithFiat = useAllAssetsBalancesWithFiat();
  const isMobile = useIsMobile();
  const isDesktop = useIsDesktop();
  const isWideScreen = useIsWideScreen();
  const {
    smallBalanceFilteringValue
  } = useUserSettings();
  useEffect(() => {
    setActivePage(1);
    if (!allAssetsBalancesWithFiat) {
      return;
    }
    const filterLower = filter.toLowerCase();
    const filtered = allAssetsBalancesWithFiat.filter(asset => (asset.balance.originSymbol?.toLowerCase().includes(filterLower) || asset.balance.key.toLowerCase().includes(filterLower)) && (!isFilteredByBalance || asset.tokenConversion >= smallBalanceFilteringValue));
    setFilteredAssets(filtered);
  }, [allAssetsBalancesWithFiat, smallBalanceFilteringValue, filter, isFilteredByBalance]);
  const rows = useMemo(() => {
    if (!filteredAssets) {
      return null;
    }
    return filteredAssets.slice((activePage - 1) * 10, activePage * 10).map(asset => <AssetRow key={asset.erc20Id} asset={asset} />);
  }, [filteredAssets, activePage]);
  return <div data-sentry-component="Assets" data-sentry-source-file="Assets.tsx">
      <Group mb={'xs'} justify={'space-between'} data-sentry-element="Group" data-sentry-source-file="Assets.tsx">
        <Title order={2} size={'h4'} mt={'auto'} lh={1} fw={'normal'} data-sentry-element="Title" data-sentry-source-file="Assets.tsx">
          {t('assets')}
        </Title>
        <Group data-sentry-element="Group" data-sentry-source-file="Assets.tsx">
          <Pagination activePage={activePage} setActivePage={setActivePage} total={filteredAssets?.length || 0} data-sentry-element="Pagination" data-sentry-source-file="Assets.tsx" />
          <ImportTokens data-sentry-element="ImportTokens" data-sentry-source-file="Assets.tsx" />
          <TextInput value={filter} onChange={event => setFilter(event.currentTarget.value)} leftSection={<IoSearch />} rightSection={<ActionIcon aria-label={t('clear')} onClick={() => setFilter('')}>
                {filter && <MdOutlineClear />}
              </ActionIcon>} placeholder={t('searchAssets')} classNames={{
          section: classes.filterIcon,
          input: classes.filterInput
        }} aria-label={t('searchAssets')} data-sentry-element="TextInput" data-sentry-source-file="Assets.tsx" />
        </Group>
      </Group>

      <Group mb={'xs'} justify={'flex-end'} gap={'xs'} data-sentry-element="Group" data-sentry-source-file="Assets.tsx">
        <Checkbox checked={isFilteredByBalance} onChange={e => setIsFilteredByBalance(e.currentTarget.checked)} data-sentry-element="Checkbox" data-sentry-source-file="Assets.tsx" />
        <Text size={'xs'} data-sentry-element="Text" data-sentry-source-file="Assets.tsx">{t('hideSmallBalances')}</Text>
      </Group>

      <Card p={0} mih={isDesktop ? 642 : 538} style={{
      overflow: 'auto'
    }} mb={'md'} data-sentry-element="Card" data-sentry-source-file="Assets.tsx">
        <Box miw={624} data-sentry-element="Box" data-sentry-source-file="Assets.tsx">
          <Table className={classes.table} verticalSpacing={'xs'} striped px={'xl'} fz={isMobile ? 'xs' : 'sm'} data-sentry-element="Table" data-sentry-source-file="Assets.tsx">
            <Table.Thead data-sentry-element="unknown" data-sentry-source-file="Assets.tsx">
              <Table.Tr data-sentry-element="unknown" data-sentry-source-file="Assets.tsx">
                <Table.Th style={{
                width: isWideScreen ? '4%' : '6%'
              }} data-sentry-element="unknown" data-sentry-source-file="Assets.tsx"></Table.Th>
                <Table.Th style={{
                width: isWideScreen ? '4%' : '8%',
                padding: 0
              }} data-sentry-element="unknown" data-sentry-source-file="Assets.tsx">
                  {t('token')}
                </Table.Th>
                <Table.Th style={{
                width: '22%'
              }} data-sentry-element="unknown" data-sentry-source-file="Assets.tsx"></Table.Th>
                <Table.Th style={{
                width: '18%'
              }} data-sentry-element="unknown" data-sentry-source-file="Assets.tsx">{t('balance')}</Table.Th>
                <Table.Th style={{
                width: '15%'
              }} data-sentry-element="unknown" data-sentry-source-file="Assets.tsx">{t('tokenPrice')}</Table.Th>
                <Table.Th style={{
                width: '15%'
              }} data-sentry-element="unknown" data-sentry-source-file="Assets.tsx">{t('totalValue')}</Table.Th>
                <Table.Th style={{
                width: isWideScreen ? '8%' : '16%'
              }} data-sentry-element="unknown" data-sentry-source-file="Assets.tsx">
                  <Text ta={'right'} size={'sm'} fw={700} data-sentry-element="Text" data-sentry-source-file="Assets.tsx">
                    {t('actions')}
                  </Text>
                </Table.Th>
              </Table.Tr>
            </Table.Thead>

            <Table.Tbody data-sentry-element="unknown" data-sentry-source-file="Assets.tsx">{rows}</Table.Tbody>
          </Table>
          {!isConnected && <Stack pt={'lg'}>
              <Text ta={'center'}>
                {t('connectYourWalletForAssetsBalances')}
              </Text>
              <Center>
                <ConnectEvmWallet />
              </Center>
            </Stack>}
        </Box>
      </Card>
    </div>;
}