import { AssetAmount } from '@moonbeam-network/xcm-types';
import { readContract, readContracts } from '@wagmi/core';
import { useAddress } from 'hooks/useAddress';
import { useMoonChain } from 'hooks/useMoonChainConfig';
import { useEffect, useMemo, useState } from 'react';
import { Address, erc20Abi } from 'viem';
import { Config, useConfig } from 'wagmi';

interface XcmSdkAsset {
  id: string | undefined;
  key: string;
  symbol?: string;
}

interface BalanceWithKey {
  decimals: number;
  originSymbol: string;
  symbol: string;
  value: bigint;
  key: string;
}

interface FetchBalanceArgs {
  address: Address | undefined;
  asset: XcmSdkAsset;
  chainId: number;
  config: Config;
}

async function fetchErc20Balance({
  address,
  asset,
  chainId,
  config,
}: FetchBalanceArgs): Promise<BalanceWithKey> {
  const value = address
    ? await readContract(config, {
        address: asset.id as Address,
        abi: erc20Abi,
        chainId: chainId,
        functionName: 'balanceOf',
        args: [address],
      })
    : 0n;
  const [decimals, symbol] = await readContracts(config, {
    allowFailure: false,
    contracts: [
      {
        address: asset.id as Address,
        abi: erc20Abi,
        chainId: chainId,
        functionName: 'decimals',
      },
      {
        address: asset.id as Address,
        abi: erc20Abi,
        chainId: chainId,
        functionName: 'symbol',
      },
    ],
  });

  return {
    value,
    decimals,
    symbol,
    key: asset.key,
    originSymbol: asset.symbol || symbol,
  };
}

function useXcmSdkErc20Assets(): XcmSdkAsset[] | undefined {
  const chain = useMoonChain();

  return useMemo(() => {
    return Array.from(chain.assetsData.values())
      .filter(
        (data) =>
          !chain.isNative(data.asset) &&
          typeof data.id === 'string' &&
          data.id.startsWith('0x'),
      )
      .map((data) => ({
        id: data.id,
        key: data.asset.key,
        symbol: data.asset.originSymbol,
      }))
      .filter(Boolean) as XcmSdkAsset[];
  }, [chain]);
}

export function useErc20Balances(): AssetAmount[] | undefined {
  const chain = useMoonChain();
  const address = useAddress();
  const config = useConfig();
  const xcmSdkAssets = useXcmSdkErc20Assets();
  const [balances, setBalances] = useState<AssetAmount[] | undefined>();

  useEffect(() => {
    if (!xcmSdkAssets) {
      return;
    }

    Promise.all(
      xcmSdkAssets.map((asset) => {
        return fetchErc20Balance({
          address: address as `0x${string}`,
          asset,
          chainId: chain.id,
          config,
        });
      }),
    ).then((responses) =>
      setBalances(
        responses.map(
          ({ decimals, symbol, originSymbol, value, key }) =>
            new AssetAmount({
              amount: value,
              decimals,
              key,
              originSymbol,
              symbol,
            }),
        ),
      ),
    );
  }, [address, chain.id, config, xcmSdkAssets]);

  return balances;
}
