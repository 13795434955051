import {
  SubscanReferendaStatistics,
  fetchFromSubscanApi,
} from 'services/subscan';

export async function fetchReferendaStatistics(
  subscanApiUrl: string,
): Promise<SubscanReferendaStatistics> {
  const url = `${subscanApiUrl}/scan/referenda/statistics`;

  const response = await fetchFromSubscanApi(url, {});

  const { data } = response;

  return data as SubscanReferendaStatistics;
}
