import { SubscanResponse } from 'services/subscan';

// TTL for caching Susbcan API response. (Seconds)
export const TTL = 600;

const RATE_LIMIT_ERROR_CODE = 20008;

export async function fetchFromSubscanApi(
  url: string,
  params: Record<string, string | number | undefined>,
  signal?: AbortSignal,
  row = 100,
): Promise<SubscanResponse> {
  const headers = new Headers();

  headers.append('Content-Type', 'application/json');

  const body = JSON.stringify({
    ...params,
    row,
  });

  const requestOptions: RequestInit = {
    body,
    headers,
    method: 'POST',
    redirect: 'follow',
    signal,
  };

  let response: SubscanResponse = await fetch(url, requestOptions).then((res) =>
    res.json(),
  );

  if (response.code === RATE_LIMIT_ERROR_CODE) {
    headers.append('x-api-key', process.env.NEXT_PUBLIC_SUBSCAN_API_KEY || '');
    response = await fetch(url, requestOptions).then((res) => res.json());
  }

  return response;
}
