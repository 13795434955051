import { SubscanReferendumStatus } from 'types/governance';

export interface SubscanAccount {
  address: string;
  display?: string;
  identity?: boolean;
}

export interface SubscanReferendum {
  account: SubscanAccount;
  call_module: string;
  call_name: string;
  created_block: number;
  created_block_timestamp: number;
  latest_block_num: number;
  latest_block_timestamp: number;
  origins: string;
  origins_id: number;
  referendum_index: number;
  status: SubscanReferendumStatus;
}

export interface SubscanReferendaStatistics {
  OriginsCount: number;
  referendum_locked: string;
  referendum_participate: string;
  voting_total: number;
  confirm_total: number;
  origins: { ID: number; Origins: string; Count: number }[];
}

export interface SubscanReferendaDetail {
  abstains_count: number;
  account: SubscanAccount;
  ayes_amount: string;
  ayes_count: number;
  nays_amount: string;
  nays_count: number;
  created_block: number;
  created_block_timestamp: number;
  decision_deposit_account: SubscanAccount;
  decision_deposit_balance: string;
  deposit_balance: string;
  latest_block_num: number;
  latest_block_timestamp: number;
  origins: string;
  origins_id: number;
  referendum_index: number;
  status: SubscanReferendumStatus;
  pre_image: PreImage;
  support_amount: string;
  timeline: SubscanTimelineItem[];
}

export interface PreImage {
  amount?: string;
  author?: SubscanAccount;
  call_module?: string;
  call_name?: string;
  created_block?: number;
  hash?: string;
  params?: string;
  status?: string;
  updated_block?: number;
}

export interface SubscanTimelineItem {
  block: number;
  status: SubscanReferendumStatus;
  time: number;
  prophecy: boolean;
  index: number;
  extrinsic_index: string;
}

export interface SubscanReward {
  account: string;
  amount: string;
  block_num: number;
  block_timestamp: number;
  event_id: string;
  event_idx: number;
  event_index: string;
  event_method: string;
  extrinsic_hash: string;
  extrinsic_idx: number;
  extrinsic_index: string;
  module_id: string;
  params: string;
  stash: string;
}

export enum SubscanVoteStatus {
  AYE = 'Ayes',
  NAY = 'Nays',
  ABSTAIN = 'Abstain',
}

export enum SubscanConviction {
  NONE = '0.1',
  X1 = '1',
  X2 = '2',
  X3 = '3',
  X4 = '4',
  X5 = '5',
  X6 = '6',
}

export interface SubscanVotes {
  account: SubscanAccount;
  amount: string;
  status: SubscanVoteStatus;
  extrinsic_index: string;
  conviction: SubscanConviction;
  voting_time: number;
  delegate_account?: SubscanAccount | null;
  votes: string;
  valid: boolean;
}

export interface SubscanListResponse {
  count: number;
  list: SubscanReferendum[] | SubscanReward[] | SubscanVotes[] | null;
}

export interface SubscanResponse {
  code: number;
  message: string;
  generated_at: number;
  data:
    | SubscanListResponse
    | SubscanReferendaStatistics
    | SubscanReferendaDetail;
}
