import { Group, Pagination as PaginationMantine, Select } from '@mantine/core';
import { useTranslation } from 'next-i18next';
import { Dispatch, SetStateAction, useMemo } from 'react';
export interface Props {
  total: number;
  activePage: number;
  setActivePage: Dispatch<SetStateAction<number>> | ((page: number) => void);
  recordsPerPage?: number;
  setRecordsPerPage?: Dispatch<SetStateAction<number>>;
}
export const Pagination = ({
  total,
  activePage,
  setActivePage,
  recordsPerPage = 10,
  setRecordsPerPage
}: Props) => {
  const {
    t
  } = useTranslation();
  const totalPages = Math.ceil((total ?? 0) / recordsPerPage);
  const data = useMemo(() => {
    return [...Array(10).keys()].map(index => {
      const step = index * 5 + 5;
      return {
        value: `${step}`,
        label: t('perPage', {
          count: step
        })
      };
    });
  }, [t]);
  return <Group justify={'flex-end'} data-sentry-element="Group" data-sentry-component="Pagination" data-sentry-source-file="Pagination.tsx">
      <PaginationMantine value={activePage} onChange={setActivePage} total={totalPages} getControlProps={control => {
      if (control === 'previous') {
        return {
          'aria-label': t('icons.pagination.previous')
        };
      }
      if (control === 'next') {
        return {
          'aria-label': t('icons.pagination.next')
        };
      }
      return {};
    }} data-sentry-element="PaginationMantine" data-sentry-source-file="Pagination.tsx" />
      {setRecordsPerPage && <Select style={{
      width: 140
    }} placeholder={t('perPage', {
      count: recordsPerPage
    })} data={data} onChange={v => {
      setRecordsPerPage(+(v as string));
      setActivePage(1);
    }} />}
    </Group>;
};