import ls from 'localstorage-slim';
import {
  SubscanListResponse,
  SubscanReward,
  TTL,
  fetchFromSubscanApi,
} from 'services/subscan';

export async function fetchSubscanRewards(
  address: string,
  subscanApiUrl: string,
  signal: AbortSignal,
  cutoffTimestamp: number,
): Promise<SubscanReward[]> {
  const cacheKey = `${subscanApiUrl}${address}`;
  const cachedRewards = ls.get<SubscanReward[]>(cacheKey);

  if (cachedRewards) return cachedRewards;

  let page = 0;
  const rewards: SubscanReward[] = [];

  do {
    const response = await fetchFromSubscanApi(
      `${subscanApiUrl}/scan/account/reward_slash`,
      { address, page: page++ },
      signal,
    );
    const { list } = response.data as SubscanListResponse;

    if (!list || !list.length) break;

    rewards.push(...(list as SubscanReward[]));
  } while (rewards[rewards.length - 1].block_timestamp > cutoffTimestamp);

  ls.set(cacheKey, rewards, { ttl: TTL });

  return rewards;
}
