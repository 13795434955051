import { useFlags } from 'flagsmith/react';

export function useXcmHiddenAssetsFlag(): string[] {
  const { xcm_hidden_assets: flag } = useFlags(['xcm_hidden_assets']);

  if (!flag.enabled) return [];

  return typeof flag?.value === 'string'
    ? flag?.value.split(',').map((key) => key.toLowerCase().trim())
    : [];
}
