import { AssetAmount } from '@moonbeam-network/xcm-types';
import { coinGeckoIds } from 'configs/coinGecko';
import { useXcmHiddenAssetsFlag } from 'hooks/flags/useXcmHiddenAssetsFlag';
import { useMoonAssetFreeBalance } from 'hooks/queries/balances/useMoonChainBalances';
import { useIsCorrectChainConnected } from 'hooks/useIsCorrectChainConnected';
import { useMoonChain } from 'hooks/useMoonChainConfig';
import { useEffect, useMemo, useState } from 'react';
import { useCoinGeckoPrices } from 'recoil/coingecko';
import { useFavoriteTokens } from 'recoil/favoriteTokens';
import { useImportedTokensBalance } from 'recoil/importedTokens';
import { useFiatCurrency } from 'recoil/userSettings/hooks';
import { TokenBalance, TokenBalanceFiat } from 'types/assets';
import { AssetAmountToTokenBalance, sortAssets } from 'utils/assets';
import { calcTokenConversion } from 'utils/calc';
import { useErc20Balances } from './useErc20Balances';
import { useXc20Balances } from './useXc20Balances';

export function useAssetsBalances(): AssetAmount[] | undefined {
  const chain = useMoonChain();
  const { checkFavoriteToken } = useFavoriteTokens();
  const moonAsset = useMoonAssetFreeBalance();
  const xc20s = useXc20Balances();
  const erc20s = useErc20Balances();
  const isCorrectChain = useIsCorrectChainConnected();
  const xcmHiddenAssets = useXcmHiddenAssetsFlag();

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  return useMemo(() => {
    if (!xc20s || !erc20s || !moonAsset) {
      return undefined;
    }

    const allAssets = [
      moonAsset,
      ...xc20s
        .concat(erc20s)
        .sort((a, b) =>
          isCorrectChain ? sortAssets(a, b, chain, checkFavoriteToken) : 0,
        ),
    ];

    return allAssets.filter((asset) => !xcmHiddenAssets.includes(asset.key));
  }, [chain, checkFavoriteToken, erc20s, moonAsset, xc20s, isCorrectChain]);
}

export function useAssetsTokenBalances(): TokenBalance[] | undefined {
  const moonChain = useMoonChain();
  const [balances, setBalances] = useState<TokenBalance[] | undefined>();
  const assets = useAssetsBalances();
  const isCorrectChain = useIsCorrectChainConnected();

  useEffect(() => {
    if (assets && isCorrectChain)
      setBalances(
        assets?.map((asset) => ({
          ...AssetAmountToTokenBalance(asset, moonChain),
          coinGeckoId: coinGeckoIds[moonChain.key]?.[asset.key],
        })),
      );
  }, [moonChain, assets, isCorrectChain]);

  return balances;
}

export function useAllAssetsBalancesWithFiat(): TokenBalanceFiat[] | undefined {
  const [assets, setAssets] = useState<TokenBalanceFiat[] | undefined>();
  const assetsBalances = useAssetsTokenBalances();
  const importedTokensBalances = useImportedTokensBalance();

  const fiatPrices = useCoinGeckoPrices();
  const fiatCurrency = useFiatCurrency();
  const isCorrectChain = useIsCorrectChainConnected();

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (!assetsBalances || !importedTokensBalances || !fiatPrices) {
      return;
    }

    const allAssets = [...assetsBalances, ...importedTokensBalances];

    const allAssetsWithFiat: TokenBalanceFiat[] = allAssets.map((asset) => {
      const fiatPrice =
        (asset.coinGeckoId &&
          fiatPrices?.[asset.coinGeckoId]?.[fiatCurrency]) ||
        0;

      const tokenConversion = calcTokenConversion(asset.balance, fiatPrice);

      return {
        ...asset,
        fiatPrice,
        tokenConversion,
      };
    });

    setAssets(allAssetsWithFiat);
  }, [
    assetsBalances,
    fiatCurrency,
    fiatPrices,
    importedTokensBalances,
    isCorrectChain,
  ]);

  return assets;
}
