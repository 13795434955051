import { ActionIcon, Box, Center, Group, Table, Text, Tooltip, useMantineTheme } from '@mantine/core';
import { AssetLogo } from 'components/AssetLogo';
import { TokenAmount } from 'components/TokenAmount';
import { TokenFiatPrice } from 'components/TokenFiatPrice';
import { AssetActionMenu } from 'components/pages/overview/Assets/AssetActionMenu';
import { useIsDesktop, useIsMobile } from 'hooks/useMedia';
import { useMoonChainKeyParam } from 'hooks/useMoonChainParam';
import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import { useEffect, useState } from 'react';
import { IoStar, IoStarOutline, IoSwapHorizontal } from 'react-icons/io5';
import { useFavoriteTokens } from 'recoil/favoriteTokens';
import { TokenBalance } from 'types/assets';
import { useAccount } from 'wagmi';
export function AssetRow({
  asset
}: {
  asset: TokenBalance;
}) {
  const {
    balance,
    erc20Id,
    isNative
  } = asset;
  const [showCrossChainButton, setShowCrossChainButton] = useState(false);
  const {
    t
  } = useTranslation();
  const moonChainKey = useMoonChainKeyParam();
  const {
    checkFavoriteToken,
    addFavoriteToken,
    removeFavoriteToken
  } = useFavoriteTokens();
  const {
    isConnected
  } = useAccount();
  const isFavorite = checkFavoriteToken(erc20Id);
  const isDesktop = useIsDesktop();
  const isMobile = useIsMobile();
  const {
    other
  } = useMantineTheme();
  function renderFavoriteStar() {
    if (isNative) {
      return null;
    }
    return isFavorite ? <ActionIcon title={t('icons.removeFromFavorites')} onClick={() => removeFavoriteToken(erc20Id)} data-sentry-element="ActionIcon" data-sentry-component="renderFavoriteStar" data-sentry-source-file="AssetRow.tsx">
        <IoStar data-sentry-element="IoStar" data-sentry-source-file="AssetRow.tsx" />
      </ActionIcon> : <ActionIcon title={t('icons.addToFavorites')} onClick={() => addFavoriteToken(erc20Id)} data-sentry-element="ActionIcon" data-sentry-component="renderFavoriteStar" data-sentry-source-file="AssetRow.tsx">
        <IoStarOutline data-sentry-element="IoStarOutline" data-sentry-source-file="AssetRow.tsx" />
      </ActionIcon>;
  }
  useEffect(() => {
    if (isMobile) {
      setShowCrossChainButton(true);
    }
  }, [isMobile]);
  return <Table.Tr onMouseEnter={() => setShowCrossChainButton(true)} onMouseLeave={() => setShowCrossChainButton(false)} style={{
    fontSize: 'sm'
  }} data-sentry-element="unknown" data-sentry-component="AssetRow" data-sentry-source-file="AssetRow.tsx">
      <Table.Td style={{
      paddingRight: 0
    }} data-sentry-element="unknown" data-sentry-source-file="AssetRow.tsx">
        <Center data-sentry-element="Center" data-sentry-source-file="AssetRow.tsx">{renderFavoriteStar()}</Center>
      </Table.Td>
      <Table.Td style={{
      paddingLeft: 0,
      paddingRight: 0
    }} data-sentry-element="unknown" data-sentry-source-file="AssetRow.tsx">
        <AssetLogo p={2} size={isDesktop ? 40 : 30} assetKey={balance.key} mx={'auto'} data-sentry-element="AssetLogo" data-sentry-source-file="AssetRow.tsx" />
      </Table.Td>
      <Table.Td data-sentry-element="unknown" data-sentry-source-file="AssetRow.tsx">
        <Text fw={'bold'} w={54} display={'inline-block'} data-sentry-element="Text" data-sentry-source-file="AssetRow.tsx">
          {balance.originSymbol}
        </Text>{' '}
        {/* <Text span mr={8}>
          {originChain}
         </Text> */}
      </Table.Td>
      <Table.Td data-sentry-element="unknown" data-sentry-source-file="AssetRow.tsx">
        {isConnected ? <TokenAmount amount={balance.amount} decimals={balance.decimals} maxDecimals={4} symbol={balance.symbol} isHidable /> : <Text pl={20}>{'-'}</Text>}
      </Table.Td>
      <Table.Td data-sentry-element="unknown" data-sentry-source-file="AssetRow.tsx">
        <TokenFiatPrice showPrice assetAmount={balance} data-sentry-element="TokenFiatPrice" data-sentry-source-file="AssetRow.tsx" />
      </Table.Td>
      <Table.Td data-sentry-element="unknown" data-sentry-source-file="AssetRow.tsx">
        <TokenFiatPrice assetAmount={balance} isHidable={true} maxDecimals={2} data-sentry-element="TokenFiatPrice" data-sentry-source-file="AssetRow.tsx" />
      </Table.Td>
      <Table.Td data-sentry-element="unknown" data-sentry-source-file="AssetRow.tsx">
        <Group justify={'right'} gap={'xs'} data-sentry-element="Group" data-sentry-source-file="AssetRow.tsx">
          <Box data-sentry-element="Box" data-sentry-source-file="AssetRow.tsx">
            {showCrossChainButton && <Link href={{
            pathname: '[moonChainKey]/xcm',
            query: {
              moonChainKey,
              asset: balance.key
            }
          }}>
                <Tooltip label={t('crossChainTransfer')}>
                  <Box>
                    <ActionIcon c={other.colors.action}>
                      <IoSwapHorizontal size={'2rem'} />
                    </ActionIcon>
                  </Box>
                </Tooltip>
              </Link>}
          </Box>
          <AssetActionMenu asset={asset} data-sentry-element="AssetActionMenu" data-sentry-source-file="AssetRow.tsx" />
        </Group>
      </Table.Td>
    </Table.Tr>;
}