import { Group, Modal, Text, TextInput } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { ActionButton } from 'components/ActionButton';
import { TokenAmount } from 'components/TokenAmount';
import { useTranslation } from 'next-i18next';
import { useImportTokensForm } from './useImportTokenForm';
export function ImportTokens() {
  const {
    t
  } = useTranslation('common', {
    keyPrefix: 'importedTokens'
  });
  const {
    t: tCommon
  } = useTranslation('common');
  const [opened, {
    open,
    close: closeModal
  }] = useDisclosure(false);
  const {
    form,
    balance,
    onContractAddressBlur,
    onCoinGeckoIdBlur,
    onResetAndClose,
    onSubmit
  } = useImportTokensForm(closeModal);
  return <>
      <Modal opened={opened} onClose={onResetAndClose} title={t('importToken')} centered data-sentry-element="Modal" data-sentry-source-file="ImportTokens.tsx">
        <form onSubmit={onSubmit}>
          <TextInput placeholder={t('contractAddress')} label={t('contractAddress')} withAsterisk {...form.getInputProps('contractAddress')} onBlur={onContractAddressBlur} data-sentry-element="TextInput" data-sentry-source-file="ImportTokens.tsx" />
          <TextInput placeholder={t('coinGeckoId')} label={t('coinGeckoId')} minLength={3} {...form.getInputProps('coinGeckoId')} onBlur={onCoinGeckoIdBlur} mt={'md'} data-sentry-element="TextInput" data-sentry-source-file="ImportTokens.tsx" />

          <Text mt={'md'} data-sentry-element="Text" data-sentry-source-file="ImportTokens.tsx">
            {'Token balance:'}{' '}
            <TokenAmount symbol={balance?.symbol} amount={balance?.balance} decimals={balance?.decimals} maxDecimals={4} isHidable={true} span data-sentry-element="TokenAmount" data-sentry-source-file="ImportTokens.tsx" />
          </Text>

          <Group align={'space-between'} justify={'space-between'} mt={'md'} data-sentry-element="Group" data-sentry-source-file="ImportTokens.tsx">
            <ActionButton onClick={onResetAndClose} variant={'outline'} data-sentry-element="ActionButton" data-sentry-source-file="ImportTokens.tsx">
              {tCommon('cancel')}
            </ActionButton>
            <ActionButton type={'submit'} tt={'capitalize'} disabled={!form.isValid() || !balance || Object.keys(form.errors).length > 0} data-sentry-element="ActionButton" data-sentry-source-file="ImportTokens.tsx">
              {tCommon('save')}
            </ActionButton>
          </Group>
        </form>
      </Modal>

      <ActionButton gaName="overview:importToken" onClick={open} data-sentry-element="ActionButton" data-sentry-source-file="ImportTokens.tsx">
        {t('importToken')}
      </ActionButton>
    </>;
}