import { hasLength, useForm } from '@mantine/form';
import { getBalance } from '@wagmi/core';
import { useAddress } from 'hooks/useAddress';
import { useTranslation } from 'next-i18next';
import { useCallback, useState } from 'react';
import { useImportedTokens } from 'recoil/importedTokens';
import { checkCoinGeckoId } from 'services/coingecko';
import { Address, isAddress } from 'viem';
import { useConfig } from 'wagmi';
interface FormValues {
  contractAddress: string;
  coinGeckoId: string;
}
interface Balance {
  balance: bigint;
  decimals: number;
  symbol: string;
}
export function useImportTokensForm(closeModal: VoidFunction) {
  const {
    t
  } = useTranslation('common', {
    keyPrefix: 'importedTokens'
  });
  const config = useConfig();
  const address = useAddress();
  const {
    addImportedToken,
    isTokenImported
  } = useImportedTokens();
  const [balance, setBalance] = useState<Balance | undefined>();
  const form = useForm<FormValues>({
    initialValues: {
      contractAddress: '',
      coinGeckoId: ''
    },
    validate: {
      contractAddress: value => isAddress(value) ? isTokenImported(value) ? t('contractAddressAlreadyImported') : null : t('contractAddressInvalid'),
      coinGeckoId: value => value.length ? hasLength({
        min: 3,
        max: 20
      }, t('coinGeckoIdTooShort'))(value) : null
    },
    validateInputOnBlur: true
  });
  const onContractAddressBlur = useCallback(() => {
    form.getInputProps('contractAddress').onBlur();
    const {
      contractAddress
    } = form.values;
    if (contractAddress.length) getBalance(config, {
      address: address as Address,
      token: contractAddress as Address
    }).then(({
      decimals,
      symbol,
      value
    }) => setBalance({
      balance: value,
      symbol,
      decimals
    })).catch(() => {
      setBalance(undefined);
      form.setFieldError('contractAddress', t('contractAddressInvalid'));
    });else setBalance(undefined);
  }, [address, form, config, t]);
  const onCoinGeckoIdBlur = useCallback(() => {
    form.getInputProps('coinGeckoId').onBlur();
    const {
      coinGeckoId
    } = form.values;
    if (coinGeckoId.length) checkCoinGeckoId(coinGeckoId).then(isValidId => {
      if (!isValidId) form.setFieldError('coinGeckoId', t('coinGeckoIdInvalid'));
    }).catch(() => {
      form.setFieldError('coinGeckoId', t('coinGeckoError'));
    });
    return null;
  }, [form, t]);
  const onResetAndClose = useCallback(() => {
    setBalance(undefined);
    form.reset();
    closeModal();
  }, [form, closeModal]);
  const onSubmit = useCallback(({
    contractAddress: contract,
    coinGeckoId
  }: FormValues) => {
    addImportedToken({
      contractAddress: contract as Address,
      coinGeckoId
    }).then(isAddedSuccessfully => isAddedSuccessfully ? onResetAndClose() : form.setFieldError('contractAddress', t('contractAddressInvalid')));
  }, [addImportedToken, form, onResetAndClose, t]);
  return {
    form,
    balance,
    onContractAddressBlur,
    onCoinGeckoIdBlur,
    onResetAndClose,
    onSubmit: form.onSubmit(onSubmit)
  };
}