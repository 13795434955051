import { Stack, Title } from '@mantine/core';
import type { GetServerSideProps, NextPage } from 'next';
import { useTranslation } from 'next-i18next';
import { BalanceGrid } from 'components/BalanceGrid';
import { Assets } from 'components/pages/overview';
import { Announcements } from 'components/pages/overview/Announcements';
import { useAddress } from 'hooks/useAddress';
import { useIsCorrectChainConnected } from 'hooks/useIsCorrectChainConnected';
import { useMoonChain } from 'hooks/useMoonChainConfig';
import Head from 'next/head';
import { generateServerProps } from 'utils/server';
import { capitalize } from 'utils/text';
import { geValidMoonChainKey, isQueryParamValid } from 'utils/validation/queryParamValidation';
const Home: NextPage = () => {
  const {
    t
  } = useTranslation();
  const address = useAddress();
  const isCorrectChain = useIsCorrectChainConnected();
  const {
    key
  } = useMoonChain();
  const titleForGA = t('gaTitles.overviewChain', {
    chain: capitalize(key)
  });
  const welcomeTitle = t('gaTitles.welcomeOverviewChain', {
    chain: capitalize(key)
  });
  return <>
      <Head data-sentry-element="Head" data-sentry-source-file="index.tsx">
        <meta key={'description'} name={'description'} content={t('descriptions.overview')} data-sentry-element="meta" data-sentry-source-file="index.tsx" />
        <title>{titleForGA}</title>
      </Head>
      <Title size={'h2'} data-sentry-element="Title" data-sentry-source-file="index.tsx">{welcomeTitle}</Title>
      <Stack gap={'lg'} data-sentry-element="Stack" data-sentry-source-file="index.tsx">
        <Announcements data-sentry-element="Announcements" data-sentry-source-file="index.tsx" />
        <BalanceGrid address={address} data-sentry-element="BalanceGrid" data-sentry-source-file="index.tsx" />
        <Assets key={`${isCorrectChain}`} data-sentry-element="Assets" data-sentry-source-file="index.tsx" />
      </Stack>
    </>;
};
export default Home;
export const getServerSideProps: GetServerSideProps = async ({
  locale,
  params,
  query
}) => {
  if (!isQueryParamValid(query)) {
    const moonChainKey = geValidMoonChainKey(params?.moonChainKey as string);
    return {
      redirect: {
        destination: `/${moonChainKey}`,
        permanent: true
      }
    };
  }
  return await generateServerProps(params?.moonChainKey, locale as string, ['common']);
};