import { AssetAmount } from '@moonbeam-network/xcm-types';
import { MoonChain } from 'types/MoonChain';
import { TokenBalance } from 'types/assets';

export function sortAssets(
  a: AssetAmount,
  b: AssetAmount,
  moonChain: MoonChain,
  checkFavoriteToken: (id: string) => boolean,
): number {
  return sortTokensBalances(
    AssetAmountToTokenBalance(a, moonChain),
    AssetAmountToTokenBalance(b, moonChain),
    checkFavoriteToken,
  );
}

export function sortTokensBalances(
  a: TokenBalance,
  b: TokenBalance,
  checkFavoriteToken: (id: string) => boolean,
): number {
  if (a.isNative) {
    return -1;
  }

  if (b.isNative) {
    return 1;
  }

  const isAFavorite = checkFavoriteToken(a.erc20Id);
  const isBFavorite = checkFavoriteToken(b.erc20Id);

  if (isAFavorite && !isBFavorite) return -1;
  if (!isAFavorite && isBFavorite) return 1;

  if (a.balance.amount || b.balance.amount) {
    return b.balance.toBigDecimal().sub(a.balance.toBigDecimal()).toNumber();
  }

  const aName = a.balance.originSymbol.toLowerCase();
  const bName = b.balance.originSymbol.toLowerCase();

  if (aName < bName) {
    return -1;
  }

  if (aName > bName) {
    return 1;
  }

  return 0;
}

export function AssetAmountToTokenBalance(
  balance: AssetAmount,
  moonChain: MoonChain,
): TokenBalance {
  return {
    balance,
    erc20Id: moonChain.getErc20Id(balance),
    isImported: false,
    isNative: moonChain.isNative(balance),
  };
}
